.column{
    width: 100%;
    flex-grow: 1;
}
#main-thanks.main-thanks {
    position: relative;
    width: 100%;
    
    // min-height: 150vh; /* Fallback for browsers that do not support Custom Properties */
    // min-height: calc(var(--vh, 1vh) * 150);
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    @include tablet-portrait{
        width: 30rem;
    }
    .daten-head{
        h2{
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
        }
    }
    .share-go-back{
        flex-grow: 1;
        margin-top: 2rem;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 10rem;
        @include tablet-portrait{
            padding-bottom: 12rem;

        }
        .daten-head{
            z-index: 2;
            
            text-align: center;
            * {
                color: white;
                font-weight: bold;
                font-family: "PlayfairDisplay";
            }
            .link {
                width: 100%;
                display: flex;
                justify-content: center;
            }
            img{
                display: inline-block;
                margin-top: 1rem;
            }
        }
        .background-section{
            position: absolute;
            width: 100%;
            height: 100%;
            padding-top: 2rem;
            height: 100%;
            background-image: linear-gradient(to bottom, transparent 25%, #179c86 25%);
            @include tablet-portrait{
                padding-top: 4rem;
                width: 125%;
                left: -12.5%;
                // padding-bottom: 4rem;
            }
            @include tablet-landscape{
                // width: 150%;
                // left: -25%;

            }
            img{
                width: 100%;
                top: -4rem;
                position: relative;
                @include tablet-portrait{
                    top: -8rem;

                }
            }
        }
    }
}