.textBox{
    .text{
        // ! Daten Select
        .select{
            width: 100%;
            font-size: 18px;
            line-height: 27px;
            color: $grey;
        }
        .select__single-value {
            color: $grey;

        }
        .select__control{
            border: 0;
            box-shadow: unset;
        }
        .select__dropdown-indicator{
            transition: all .5s;
        }
        .select__control--menu-is-open{
            .select__dropdown-indicator{
                transform: rotate(180deg);
            }
        }
    }
    
}