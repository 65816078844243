.button-cta{
    * {
        font-family: "PlayfairDisplay";
        font-weight: normal;
    }
    color: white;
    padding: .5rem;
    border-radius: 8px;
    background-color: $green;
    text-align: center;
    cursor: pointer;
}