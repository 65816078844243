#about-layout, .about-layout{
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    &.paddingOverlay{
        padding-bottom: 2rem;

    }
    & > div{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .about-head,
    #about-layout-links{
        width: 85%;
        padding-bottom: 5rem;
        @include tablet-portrait{
            width: 30rem;
            padding-bottom: 10rem;
        }
        @include tablet-landscape{
            // padding-bottom: 8vh;
        }

    }
    .about-head{
        display: block;
        left: 2.5%;
        position: relative;
        padding-top: 2rem;
        padding-bottom: 2rem;
        color: $red;
        @include tablet-portrait{
            left: 0;
        }
        h2{
            font-size: 1.5rem;
            line-height: 2rem;
        }
        h3{
            font-size: 18px;
            line-height: 27px;
        }
    }
}
#about-layout-links{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 85%;
    @include tablet-portrait{
        width: 30rem;
        flex-flow: row nowrap;
    }
    *{
        font-size: 18px;
        line-height: 27px;
    }
    
    a{
        flex: 0 0 100%;
        @include tablet-portrait{
            flex: 0 0 calc(100%/3);

        }
        color: $red!important;
        opacity: 1;
        // font-size: 1rem!important;
        h3{
            margin: 0;
        }
    }
    a:hover{
        @include hover{
            text-decoration: underline;
        }
    }
}