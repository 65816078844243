#main-daten{
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    &.min {
        min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        min-height: calc(var(--vh, 1vh) * 100);
    }
    &.max {
        // max-height: 110vh;
        // max-height: calc(var(--vh, 1vh) * 110);
        // overflow-y: hidden;
        min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        min-height: calc(var(--vh, 1vh) * 100);
        align-items: flex-start;
        align-content: flex-start;
        flex-flow: column wrap;
    }
    & > div,
    & > form{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        & > .textBox{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        #prof {
            outline: unset;
        }
    }
    #daten-head, .daten-head{
        h3{
            font-size: 18px;
            line-height: 27px;
        }
        h1{
            font-size: 1.5rem;
            line-height: 2rem;
        }
        width: 85%;
        @include tablet-portrait{
            width: 30rem;
        }
    }
    #daten-head, .daten-head{
        display: block;
        // left: 2.5%;
        position: relative;
        padding-top: 2rem;
        padding-bottom: 2rem;
        color: $red;
        @include tablet-portrait{
            left: 0;
        }
    }
    a{
        width: 85%;
        display: flex;
        justify-content: center;
        @include tablet-portrait{
            width: 20rem;
        }
    }
    a svg{
        margin-top: 2rem;
        width: 100%;
        height: 58px;
        text{
            text-decoration: none;
        }
    }
    .disabled{
        pointer-events: none;
        opacity: 0.5;
    }
    .padding-right .text{
            padding: .532rem;
    }
}