#intro{
    
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    position: relative;
    top: 0;
    // background-color: red;
    left: 0;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
    .daten-head{
        display: block;
        // left: 2.5%;
        position: relative;
        padding-top: 2rem;
        // padding-bottom: 2rem;
        color: $red;
        width: 85%;
        text-align: center;
        @include tablet-portrait{
            width: 30rem;
        }
        @include tablet-portrait{
            left: 0;
        }
        h3{
            font-size: 18px;
            line-height: 27px;
        }
        h1{
            font-size: 1.5rem;
            line-height: 2rem;
        }
        
    }
    .disabled{
        pointer-events: none;
        opacity: 0.5;
    }
    
}
