#datenschutz-main.datenschutz-main {
    position: relative;
    width: 85%;
    padding-top: 2rem;
    margin-bottom: 15rem;
    @include tablet-portrait{
        padding-bottom: 6rem;

    }
    *{
        color: $red!important;
    }
    // padding-bottom: 2rem;
    @include tablet-landscape{
        padding-top: 2rem;
        width: 30rem;
    }
    p:first-child {
        @include tablet-portrait{
            width: 100%;
            // padding-right: 4rem;
            box-sizing: border-box;
        }
    }
}