#main-login{
    flex: 0 0 100%;
    position: relative;
    margin-bottom: 20vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    #daten-head{
        width: 100%;
        display: flex;
        justify-content: center;
        *{
            color: $red;
        }
    }
}