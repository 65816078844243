#header{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    svg{
        @include tablet-landscape{
            width: 23rem;
            height: auto;
        }
    }
    .hilfe-cta{
        position: fixed;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        right: 0;
        top: 10rem;
        z-index: 999;
        width: 60px;
        height: 40px;
        @include tablet-portrait{
            // padding-left: 2rem;
            // padding-right: 2rem;
            position: fixed;
        }
        @include tablet-landscape{
            // padding-left: 3rem;
            // padding-right: 3rem;

        }
        a{
            width: auto;
            color: white;
            text-decoration: none;
        }
        h3{
            margin: 0;
        }
        .x{
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: auto;
                height: 1.6rem;
            }
        }
    }
}