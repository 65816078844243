#snow-end{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

#snow-end-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 20;
    #intro-snow{
        position: absolute;
        bottom: -.3rem;
        width: 100%;
        height: auto;
        z-index: 20;
        @include tablet-landscape{
            bottom: -10rem;
            bottom: -15vw;
        }
        @include big-desktop {
            bottom: -15vw;
        }
        @include ipad-pro-portrait{
            bottom: -10vw;
        }
    }
    
    #bbdo{
        position: absolute;
        bottom: 0;
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 20;
        // background-color: red;
        opacity: 1;
        height: 126px;
        bottom: -.5rem;
        @include tablet-portrait{
            bottom: -1rem;

        }
        @include tablet-landscape{
            // bottom: -8rem;
            bottom: -11vw;
        }
        @include big-desktop {
            bottom: -11vw;
        }
        @include ipad-pro-portrait{
            bottom: -11vw;
        }
        svg g{
            // width: 10em;
            // height: 25vh;
            // position: relative;
            // @include tablet-portrait{
            //     height: 15rem;
            // }
        }
    }
    &.normal{
        z-index: 8;
        #bbdo{
            @include tablet-portrait{
                bottom: -1rem;
            }
            @include tablet-landscape{
                // bottom: -8rem;
                bottom: -14vw;
            }
            @include big-desktop {
                bottom: -15vw;
            }
            @include ipad-pro-portrait{
                bottom: -11vw;
            }
        }
    }

}