.cartClass {
    height: 55px;
    padding: 10px 12px;
    padding-top: 17.5px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
  position: relative;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    width: 75%;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    left: 12.5%;
    @include tablet-portrait {
      left: 0;
      width: 30rem;
    }
    
}

.cartClass--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.cartClass--invalid {
  border-color: #fa755a;
}

.cartClass--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe-container {
    width: 100%;
    text-align: center;
    margin-top: 0.8rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @include tablet-portrait {
        width: 30rem;
    }
    form{
      width: 100%;
      button{
        font-family: "PlayfairDisplay-Bold";
        background-color: $green;
        font-size: 18px;
        line-height: 27px;
        color: white;
        border: none;
        border-radius: 4px;
        padding: .5rem;
        margin-bottom: 1rem;
      }
    }
    label{
      width: 100%;
    }
    .StripeElement{
      // left: 12.5%;
      position: relative;
      height: 55px;
      @include tablet-portrait{
        left: 0;
      }
    }
}
.StripeElement,.paypalButtons {
    width: 75%;
    @include tablet-portrait{
        width: 30rem;
    }
}

