#main-story.main-story{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    // padding-bottom: 2rem;
    @include tablet-landscape{
        width: 30rem;
    }
    .story-bild{
        width: 85%;
        @include tablet-portrait{
            width: 30rem;
        }
        .story-bild-foto{
            width: 100%;
        }
    }
    .story-section{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        position: relative;
        *{
            position: relative;
            z-index: 2;
        }
        .background-section{
            position: absolute;
            width: 100%;
            left: 0%;
            height: 100%;
            top: 0px;
            z-index: 0;
            // @include tablet-portrait{
            //     width: 100vw;
            //     left: -21vw;
            // }
            @include tablet-landscape{
                width: 150%;
                left: -25%;

            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        &.first-section{
            // padding-bottom: 8rem;
            padding-bottom: 2rem;
        }
        &.second-section{
            // margin-top: -8rem;
            margin-top: -4rem;
            padding-bottom: 4rem;
        }
        &.third-section{
            margin-top: -8rem;
            padding-top: 8rem;
            padding-bottom: 16rem;
            width: 100%;
            @include tablet-portrait{
                margin-top: -9rem;
                padding-top: 9rem;
                padding-bottom: 26rem;
                // width: 30rem;
            }
            @include desktop{
                padding-bottom: 20rem;

            }
            .about-layout{
                margin-top: 2rem;
                padding-bottom: 2rem;
                a{
                    color: white!important;
                    opacity: 1;
                }
            }
            .background-section{
                background-image: linear-gradient(to bottom, transparent 25%, #179c86 25%);
                overflow-y: hidden;
                img{
                    // height: 100%;
                    width: 100%;
                }
            }
        }
    }
}