.textBox{
    .text{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        background-color: white;
        // box-shadow: 0px 0px 10px 2px rgba(255,0,0,0.2), 
        //         0px 0px 9px 10px $rosa;
        border-radius: 8px;
        width: 85%;
        margin-bottom: 1rem;
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        align-items: center;
        flex-flow: flex-start;
        @include tablet-portrait{
            width: 30rem;
            padding-right: 1rem;
        }
        
        h3{
            left: -.5rem;
            position: relative;
            color: $grey;
            @include tablet-landscape{
                left: 0;
            }
            
        }
        .select__value-container,
        .select__indicator{
            padding-top: 0;
            padding-bottom: 0;
        }
        .select__indicator-separator{
            display: none;
        }
        .select__control{
            min-height: unset;
            .css-b8ldur-Input{
                padding-top: 0;
                padding-bottom: 0;
                // margin-top: 0;
                margin-bottom: 0;
            }
        }
        input[type='text']{
            width: 100%;
            color: $grey;
            font-size: 18px;
            line-height: 27px;
            font-weight: normal;
            font-family: "Imprima-Regular";
            border: 0;
            outline: none;
            padding-left: 8px;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $grey;
                opacity: 0.8;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $grey;
                opacity: 0.8;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $grey;
                opacity: 0.8;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $grey;
                opacity: 0.8;
            }
        }
        &.disabled{
            pointer-events: none;
            opacity: 0.5;
        }
    }
    &.withSvg{
        svg{
            position: relative;
            flex: 0 0 20%;
            left: -1.5rem;
            // box-shadow: 0px 0px 10px 2px rgba(255,0,0,0.3);
            //     border-radius: 50px;
            @include tablet-landscape{
                flex: 0 0 12.5%;
            }
            .st3{
                fill: white;
            }
        }
    }
    &.padding-right{
        .text{
            padding-right: 1rem;
        }
    }
    
    
}