.tree{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 60%;
    padding-bottom: 3rem;
    pointer-events: none;
    @include mini {
        padding-bottom: 2rem;

    }
    @include tablet-portrait{
        // padding-bottom: 12vh;
        padding-bottom: 7rem;
        
    }
    
    @include tablet-landscape{
        padding-right: 70%;
        padding-bottom: 3rem;
        padding-bottom: 0rem;
        
    }
    @include ipad-pro-portrait{
        padding-bottom: 5vw;
    }
    @include big-desktop {
        padding-right: 75%;
        padding-bottom: 0rem;
    }
    svg {
        width: auto;
        height: 25vh;
        position: relative;
        @include tablet-portrait{
            height: 15rem;
        }
    }
}