#main-single-student.main-single-student {
    position: relative;
    width: 100%;
    @include tablet-portrait{
        width: 30rem;
    }
    #about-layout-links{
        margin-top: 2rem;
        padding-bottom: 2rem;
        *{
            color: white!important;
        }
    }
    .wuenschen-list{
        width: 100%;
        .wuensch-container{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            border-top-right-radius: 35px;
            // padding-bottom: 5rem;
            margin-top: 4rem;
            position: relative;
            transition: all .5s;
            
            align-items: center;
            justify-content: flex-end;
            @include tablet-portrait{
                justify-content: flex-end;
                border-top-left-radius: 70px;
                width: 125%;
                left: -12.5%;
            }
            @include tablet-landscape{
                justify-content: center;
                width: 150%;
                left: -25%;
            }
            .wuensch-name{
                width: 75%;
                @include tablet-portrait{
                    width: 30rem;
                }
                h2{
                    margin-bottom: 0;
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                p{
                    margin-bottom: 0;
                }
                * {
                    font-family: "PlayfairDisplay";
                    color: white;
                    font-weight: bold;
                }
            }
            .pfeile{
                position: absolute;
                left: 2rem;
                cursor: pointer;
                transform: rotate(180deg);
            }
        }
        .rest-single-content{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            position: relative;
            transition: all .5s;
            align-items: center;
            justify-content: center;
            padding-bottom: 10rem;
            @include tablet-portrait{
                padding-bottom: 17rem;
                justify-content: center;
                width: 125%;
                left: -12.5%;
                // padding-bottom: 20rem;
            }
            @include tablet-landscape{
                padding-bottom: 20rem;
                justify-content: center;
                width: 150%;
                left: -25%;
            }
            .trennen{
                position: relative;
                width: 75%;
                @include tablet-portrait{
                    width: 30rem;
                }
                height: 1px;
                margin-top: 1rem;
                margin-bottom: 2rem;
                background-color: white;
            }
            
            .bildBox{
                width: 100%;
                text-align: center;
                margin-bottom: 2rem;
                @include tablet-portrait {
                    width: 30rem;
                }
                img{
                    border-radius: 8px;
                    display: inline-block;
                    width: 80%;
                    @include tablet-portrait{
                        width: 100%;

                    }
                }
                .secondImg{
                    transform: scale(.8);
                    height: 100%;
                    width: auto;
                    @include tablet-portrait{
                        transform: scale(.8);

                    }
                }
                p{
                    margin: 0;
                    color: white;
                    font-size: 18px;
                    line-height: 27px;
                    width: 75%;
                    left: 12.5%;
                    position: relative;
                    @include tablet-portrait{
                        left: 0;
                        width: 100%;
                    }
                }
            }
            .head{
                width: 75%;
                @include tablet-portrait{
                    width: 30rem;
                }
                h2{
                    margin-bottom: 0;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-family: "PlayfairDisplay";
                }
                * {
                    color: white;
                    font-weight: bold;
                }
                &.head-flex{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    h2{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}