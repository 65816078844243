#main-about{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 2rem;
    & > a{
        width: 85%;
        @include tablet-portrait{
            width: 20rem;
        }
    }
    & > a svg{
        margin-top: 2rem;
        width: 100%;
        text{
            text-decoration: none;
        }
    }
    .aboutCTA{
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
    }
    .about-layout{
        margin-top: 2rem;
        padding-bottom: 2rem;
    }
}
