@import "styles/mixins";
@import "styles/vars";
@import "styles/snow";
@import "styles/intro";
@import "styles/header";
@import "styles/login";
@import "styles/afterlogin";
@import "styles/about";
@import "styles/textBox";
@import "styles/aboutLayout";
@import "styles/daten";
@import "styles/select";
@import "styles/hilfe";
@import "styles/detail";
@import "styles/datenschutz";
@import "styles/button";
@import "styles/tree";
@import "styles/story";
@import "styles/wuenschen";
@import "styles/detail_wunsch";
@import "styles/stripe";
@import "styles/share";
@import "styles/thankyou";

@font-face {
  font-family: "Imprima-Regular";
  src: url("./assets/Fonts/Imprima/Imprima-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay";
  src: url("./assets/Fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay";
  src: url("./assets/Fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url("./assets/Fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

html, 
body {
    margin: 0;
    font-family: "Imprima-Regular";
    font-weight: normal;
}
* {
    box-sizing: border-box;
    // hyphens: auto;
}
h3,h2,h1,a{
    font-weight: normal;
}
#main{
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 10;
}
.App{
    position: relative;
    width: 100%;
    z-index: 10;

}
.snowing{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 15;
    pointer-events: none;
}
#root{
    position: relative;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    transition: opacity 0.5s;
}

a{
    text-decoration: none;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

h1, h2{
    font-size: 1.5rem!important;
    line-height: 2rem!important;
}

h3,h4,h5,h6,p {
    font-size: 18px!important;
    line-height: 27px!important;

}
.footer{
    @include tablet-landscape{

        margin-bottom: 15rem;
    }
    @include desktop{
        margin-bottom: 12rem;
    }
    @include big-desktop{
        margin-bottom: 8rem;

    }
}
.logos{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    // align-items: center;
    padding-top: 2rem;
    position: absolute;
    z-index: 99;
    @include tablet-landscape{
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-around;

    }
    svg{
        width: auto;
        height: 2rem;
        margin-bottom: 3rem;
        @include tablet-landscape{
            margin-bottom: 0rem;
            margin-left: 1rem;
            margin-right: 1rem;

        }
    }
    svg:nth-child(1){
        height: 1.5rem;
    }
    svg:nth-child(6){
        height: 2.5rem;
    }
}

