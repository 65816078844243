#main-wuenschen.main-wuenschen{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    @include tablet-portrait{
        width: 30rem;
    }
    .wuenschen-list{
        width: 100%;
        .wuensch-container{
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            border-top-right-radius: 35px;
            padding-bottom: 5rem;
            margin-top: -5rem;
            position: relative;
            transition: all .5s;
            cursor: pointer;
            align-items: center;
            justify-content: flex-start;
            @include tablet-portrait{
                justify-content: center;
                border-top-left-radius: 70px;
                width: 125%;
                left: -12.5%;
            }
            @include tablet-landscape{
                width: 150%;
                left: -25%;
            }
            .wuensch-name{
                width: 75%;
                padding-left: 2rem;
                @include tablet-portrait{
                    padding-left: 0rem;
                    width: 30rem;
                }
                h2{
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                * {
                    font-family: "PlayfairDisplay";
                    color: white;
                    font-weight: bold;
                }
            }
            .pfeile{
                position: absolute;
                right: 2rem;
            }
            &:nth-child(1){
                margin-top: 0;
            }
            &:hover{
                @include hover{

                    padding-bottom: 10rem;
                }
                
            }
            &:nth-child(3n + 1){
                background-color: #f08686;
            }
            &:nth-child(3n + 2){
                background-color: #d43938;
            }
            &:nth-child(3n + 3){
                background-color: #91a2ca;
            }
        }
    }
    .story-section{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        position: relative;
        *{
            position: relative;
            z-index: 2;
        }
        .background-section{
            position: absolute;
            width: 100%;
            left: 0%;
            height: 100%;
            top: 0px;
            z-index: 0;
            // @include tablet-portrait{
            //     width: 100vw;
            //     left: -21vw;
            // }
            @include tablet-portrait{
                width: 125%;
                left: -12.5%;

            }
            @include tablet-landscape{
                width: 150%;
                left: -25%;

            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        &.third-section{
            margin-top: -8.5rem;
            padding-top: 6rem;
            padding-bottom: 16rem;
            width: 100%;
            @include tablet-portrait{
                margin-top: -9.5rem;
                padding-top: 7rem;
                padding-bottom: 26rem;
                // width: 30rem;
            }
            @include tablet-landscape{
                margin-top: -9rem;
                padding-bottom: 18rem;
                // width: 30rem;
            }
            @include big-desktop{}
            #about-layout-links{
                margin-top: 2rem;
                padding-bottom: 2rem;
                *{
                    color: white!important;
                }
            }
            .background-section{
                overflow-y: hidden;
                background-image: linear-gradient(to bottom, transparent 50%, #179c86 50%);
                img{
                    height: 100%;
                }
            }
        }
    }
}
