#main-share.main-share {
    position: relative;
    width: 100%;
    @include tablet-portrait{
        width: 30rem;
    }
    #about-layout-links{
        padding-bottom: 2rem;
        margin-top: 0;
    }
    .daten-head{
        h2{
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            font-family: "PlayfairDisplay";
        }
    }
    .share-go-back{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;
        margin-bottom: 3rem;
        .daten-head{
            z-index: 2;
            
            * {
                font-family: "PlayfairDisplay";
                color: white;
                font-weight: bold;
            }
            .link {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                svg{
                    margin-top: 0;
                    width: 100%;
                    height:58px!important;
                    @include tablet-portrait{
                        height:58px!important;
                        // width: 20rem;
                    }
                }
            }
        }
        .background-section{
            position: absolute;
            width: 100%;
            height: 150%;
            @include tablet-portrait{
                width: 125%;
                left: -12.5%;

            }
            @include tablet-landscape{
                // width: 150%;
                // left: -25%;

            }
            img{
                height: 100%;
                width: 100%;
            }
        }
    }

    #share-buttons{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding-bottom: 2rem;
        *{
            flex: 0 0 75%;
        }
        @include tablet-portrait{
            *{
                flex: 0 0 100%;
            }

        }
        button{
            outline: unset;
        }
        .button-cta{
            font-family: "PlayfairDisplay";
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 2rem;
            color: white;
            padding: .5rem;
            border-radius: 8px;
            text-align: center;
            cursor: pointer;
            margin-bottom: 1rem;
            outline: unset;
        }
        .fb{
            background-color: #3e5895;
        }
        .linkedin{
            background-color: #187ab2;
        }
        .whats{
            background-color: #25d366;

        }
    }
}