@mixin mini {

    @media (max-width: 300px) { @content; }
  
  }
@mixin phone {

    @media (max-width: 599px) { @content; }
  
  }
   

  @mixin tablet-portrait {
  
    @media (min-width: 600px) { @content; }
  
  }

  @mixin ipad-pro-portrait {
    /* Portrait and Landscape */
    @media only screen 
      and (min-device-width: 1024px) 
      and (max-device-width: 1366px) 
      and (-webkit-min-device-pixel-ratio: 1.5)
      and (orientation: portrait)
       { @content; }

  }
  
  @mixin tablet-landscape-max {
  
    @media (max-width: 899px) { @content; }
  
  }
  @mixin tablet-landscape {
  
    @media (min-width: 900px) { @content; }
  
  }
  
  @mixin big-tablet-portrait {
  
    @media (min-width: 1024px) { @content; }
  
  }
  
  @mixin desktop {
   
    @media (min-width: 1200px) { @content; }
  
  }
    
  @mixin big-desktop {
  
    @media (min-width: 1800px) { @content; }
   
  }
  
   @mixin hover {
    @media (hover: hover) { @content; }
  }
  @mixin filter($properties) {
    // Your vendor prefixing stuff here, I am using Bourbon:
    @include prefixer(filter, $properties, webkit moz spec);
  } 
  
  
  @mixin aspect-ratio($ratio-or-width, $height: null) {
    $padding: if($height, 
                 percentage($height/$ratio-or-width), 
                 percentage(1/$ratio-or-width)
              );
    &:before { content:''; float: left; padding-bottom: $padding;  }
    &:after  { content:''; display: table; clear: both;  } 
  } 
  @mixin transform($transforms) {
         -moz-transform: $transforms;
           -o-transform: $transforms;
          -ms-transform: $transforms;
      -webkit-transform: $transforms;
            transform: $transforms;
  }
  // scale
  @mixin scale($scale) {
       @include transform(scale($scale));
  }
  
  @mixin rotate($rotate) {
       @include transform(rotate($rotate));
  } 